import React from "react";
import classes from './styles.module.css'
import trentialMobileImage from '../assets/images/trential-mobile.svg'
import nsdcLogoHeader from '../assets/images/nsdc/nsdc-logo-header.png'
import nsdcLogo from '../assets/images/nsdc/nsdc-logo.png'
import nsdcMobileImage from '../assets/images/nsdc/nsdc-demo.png'
import trentialBlackLogo from '../assets/images/trential-black-logo.svg'
import walletImage from '../assets/images/wallet.svg'
import googlePlayStoreImage from '../assets/images/google-play-store.svg'
import appleAppStoreImage from '../assets/images/apple-app-store.svg'
import qrButtonImage from '../assets/images/qr-button-image.svg'
import {QRCodeSVG} from 'qrcode.react';
import { Oval } from "react-loader-spinner";

const mobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};

const downloadText = "Before proceeding further, you need to download the Skill Passport app"

const steps = [
  "Open Trential app on your phone.",
  "Tap Scan Code icon in the app and scan this QR code.",
  "You will receive a popup on the app screen.",
  "Click it and accept the credential offer.",
  "Your digital degree is added to your Trential Wallet."
]

const stores = [
  {
    alt: "google-play-store-img",
    image: googlePlayStoreImage,
    url: process.env.REACT_APP_GOOGLE_PLAYSTORE_LINK
  },
  {
    alt: "apple-app-store-img",
    image: appleAppStoreImage,
    url : process.env.REACT_APP_APPLE_APPSTORE_LINK
  }
]

const payload = window.location.pathname.split("payload=")[1]
let os = mobileOperatingSystem();

const Deeplink = () => {
  React.useEffect(() => {
    if(!document.URL.includes("application")){
      if (os === "Android") {
        console.log(os);
        window.location.replace(stores[0].url);
      } else if (os === "iOS") {
        console.log(os);
        window.location.replace(stores[1].url);
      } else {
        console.log(os);
      }
    }
    else {
      window.location.replace(`nsdc://skillpass=${document.URL}`);
    }
  }, []);

  if(os === "Android" || os === "iOS"){
    return (
      <div style={{height: "100vh", width: "100%", display: "grid", placeItems: "center"}}>
        <Oval
          height={80}
          width={80}
          color="grey"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="grey"
          strokeWidth={3}
          strokeWidthSecondary={3}
        />
      </div>
    )
  }

  return (
    <div className={classes.mainContainer}>
    <div className={classes.header}>
      <img src={nsdcLogoHeader} alt="trential-logo" className={classes.trentialLogo}/>
    </div>
    <div className={classes.wrapper}>
       <section className={classes.topContainer}>
         <div className={classes.downloadInfoContainer}>
           <div className={classes.walletDiv}>
              <img src={nsdcLogo} alt="wallet-img" className={classes.walletImage}/>
              <div className={classes.downloadTextGroup}>
                <p className={classes.subheading}>Download the Skill Passport App</p>
                <p className={classes.caption}>{downloadText}</p>
              </div>
           </div>
           <div className={classes.stores}>
             {
              stores.map((store, index) => 
               <a key={index} href={store.url} target="_blank" rel="noreferrer">
                <img src={store.image} alt={store.alt} className={classes.storeImage} />
               </a>
              )
             }
           </div>
         </div>
         <div>
         <QRCodeSVG
            value={window.location.href}
            level={"L"}
            fgColor="#000"
            style={{width: "7vw", height: "auto"}}
           />
         </div>
       </section>
       {/* {
        payload?.length > 0 && 
        <section className={classes.bottomContainer}>
        <div>
          <p className={classes.heading}>Already have the Skill Passport App installed?</p>
          <div>
            <ul>
            {steps.map((step, index) => 
              index === 1 ? 
              <li key={step} className={classes.body} >Tap Scan Code <img style={{alignSelf: "center"}} src={qrButtonImage} alt="qr-button-img" className={classes.scanIcon} /> icon in the app and scan this QR code.</li>
              : <li key={step} className={classes.body}>{step}</li>
            )}
            </ul>
          </div>
        </div>
        <div className={classes.qrContainer}>
          <QRCodeSVG
            value={payload}
            level={"L"}
            fgColor="#000"
            style={{width: "20vw", height: "auto"}}
           />
        </div>
       </section>
       } */}
    </div>
    </div>
  );
};

export default Deeplink;
